import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer, ReferenceLine, ReferenceArea } from 'recharts'
import { omit,merge,groupBy, sortBy } from 'lodash'
import { CompetenceData } from '..'
import { Grid } from '@material-ui/core'
import { Attendee } from 'src/types'
import { ContactlessOutlined } from '@material-ui/icons'
type Props = {
    inputData?: CompetenceData[],
    selectedAttendee?: Attendee,
    selectedAttendeeData?: CompetenceData[],
    fullyCompletedSurveysOnly:boolean
    
}

const useStyles = makeStyles((theme) => ({
    container: {
        border: '2px solid gray',
        marginTop:'0.5em',
        marginBottom:'0.5em',
        marginLeft:'0.5em',

        padding: '0.25em',
        display: 'flex',
        flexDirection: 'column',
        displayContent: 'center',
        alignContent: 'center',
        height: '100%',
        width:'100%',
        maxHeight:800,
        maxWidth:1200
    },
    chartContainer: {
        width: '90%',
        height: '100%',
    },
    header: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 21,
    },
    subheading: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 14,
    }
}))

const colors = [
    '#8f45b5',
    '#26377B',
    '#3e7287',
    '#400e59',
    '#0e594d',
  ];

const TruncatedTick = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: { value: string };
  }) => (
    <g transform={`translate(${x},${y + 40}) rotate(315)`}>
      <text x={0} y={0} rotate={0} textAnchor="middle" dy="0.5em" fontSize={8}>
        {payload.value &&
          payload.value.split('-').map((item) => (
            <tspan key={item.trim()} textAnchor="middle" x="-30" dy="1em">
              {item.trim()}
            </tspan>
          ))}
      </text>
    </g>
);

const AverageAssessmentByCompetencyChart:React.FC<Props> = ({ inputData, selectedAttendee, selectedAttendeeData, fullyCompletedSurveysOnly }) => {
    const classes = useStyles()     


    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<any[]>([])
    const [surveyTitles, setSurveyTitles] = useState<string[]>([])
    useEffect(() => {
        void (async () => {
            setLoading(true)
            const competences:any[] = []
            inputData?.map((c:CompetenceData)=>{
                const comp:any = {name:c.statement}
                comp[`${c.surveyName}`] = Math.round(c.avgLevel)
                competences.push(comp)
            })
            const grouped = groupBy(competences, 'name')
            const merged:any[] = Object.values(grouped).map(array=> {
                let result = {}
                array.forEach(a=>{
                    result = merge(result, a)
                })
                return result;
            })
            const foundData = merged
            const dataKeys: string[] = []
            foundData.map((competence: any) => {
                Object.keys(omit(competence, 'name')).map((key) => {
                    if (!dataKeys.includes(key)) {
                        dataKeys.push(key)
                    }
                })
            })
            setSurveyTitles(dataKeys)
            setData(sortBy(foundData, 'name'))

            setLoading(!inputData)
        })()
    },[inputData])

    const yAxisTicks = () =>{
        const numbericValues:number[] = []
                
        data.map(e=> {
                Object.keys(omit(e, 'name')).map((key) => {
                    numbericValues.push(e[key]);
                }) })
        const maxValue:number = Math.max(...numbericValues)
        const result:number[] = []
        const fullScale:number[] = [0,10,20,30,40,50,60,70,80,90,100]
        fullScale.map(x=>{
            if((maxValue - x) > -25){
                result.push(x)
            }
        })
        return result;
       
    }

const CustomBar = (props:any) => {
    const { fill, x, y, width, height, t, name, dataKey } = props;
    // console.log('CustomBar props', props);
    console.log('CustomBar props', name, dataKey, y, height);
    const selectedCustomerSurveyInfo = selectedAttendeeData?.find(s=> {
        return s.statement===name && s.surveyName===dataKey;
    });
    const selectedOverallSurveyInfo = inputData?.find(s=> {
        return s.statement===name && s.surveyName===dataKey;
    });
    console.log('selected overall data', selectedOverallSurveyInfo);
    console.log('selected attendee data', selectedCustomerSurveyInfo);
    const avgLevelOverall = selectedOverallSurveyInfo ? Math.round(selectedOverallSurveyInfo?.avgLevel) : 0;
    const avgLevelForSelectedAttendee = selectedCustomerSurveyInfo ? Math.round(selectedCustomerSurveyInfo?.avgLevel) : 0;
    let targetY = y +  height*(avgLevelOverall - avgLevelForSelectedAttendee)/avgLevelOverall;

    return <svg>
        <rect x={x} y={y} width={width} height={height} stroke="none" fill={fill}/>
        {selectedCustomerSurveyInfo && <line x1={x} x2={x + (width)} y1={targetY} y2={targetY} stroke={"red"}  stroke-width="3"/>}
    </svg>;
};
    return (
        <Grid className={classes.container}>
            <p className={classes.header}>Average assessment score by competency</p>
            { fullyCompletedSurveysOnly && <p className={classes.subheading}>(*based on participants completing all 3 surveys)</p>}
            {(!inputData || loading) && (
                <p>Loading...</p>
            )}
            
            <div className={classes.chartContainer}>
                {!loading && data.length === 0 && (
                    <p>No data could be found</p>
                )}
            {!loading && data.length > 0 && (
                <ResponsiveContainer>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 0"/>
                        <Legend verticalAlign="top" />
                        <XAxis 
                            dataKey="name"
                            height={150}
                            interval={0}
                            tick={({ x, y, payload }) => (
                                <TruncatedTick x={x} y={y} payload={payload} />
                            )}
                        />
                        <YAxis ticks={yAxisTicks()}/>
                        <Tooltip />
                        {surveyTitles.map((title, index) => (
                            <Bar dataKey={title} key={title} fill={colors[index % colors.length]} shape={<CustomBar dataKey={title}/>}  isAnimationActive={false}/>  
                        ))}
                    </BarChart>
            
                </ResponsiveContainer>
            )}
            </div>
        </Grid>
    )
}

export default AverageAssessmentByCompetencyChart